import React, { useCallback, type FC } from 'react';
import { useField, type FieldInputProps } from 'react-final-form';
import { Box, PhoneInput, TextInput, Superscript, Select } from '@superside/ui';
import type { FormField } from '../../../types';
import { validateField } from '../../../utils';

type FullFormFieldProps = {
  field: FormField;
  className?: string;
  disabled?: boolean;
  variant?: 'outline' | 'fill';
};

export const FullFormField: FC<FullFormFieldProps> = (props) => {
  const { field, className, disabled, variant = 'outline' } = props;

  const { type, name, label, options, placeholder, autocomplete } = field;

  const { input: inputProps, meta } = useField(name, {
    validate: async (value) => (await validateField({ field, value })).message
  });

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLElement>) => {
      if (!disabled) {
        inputProps.onChange(e);
      }
    },
    [disabled, inputProps.onChange]
  );

  const { error, submitError, invalid, touched, dirtySinceLastSubmit } = meta;

  const hasError = touched && invalid && !dirtySinceLastSubmit;

  const Input: FC<FieldInputProps<any, HTMLElement>> = useCallback((inputProps) => {
    switch (type) {
      case 'email':
      case 'text':
        return (
          <TextInput
            {...inputProps}
            id={name}
            autoFocus
            type={type}
            autoComplete={autocomplete}
            placeholder={placeholder}
            variant={variant}
            size='sm'
            className={className}
          />
        );
      case 'phone':
        return (
          <PhoneInput
            {...inputProps}
            autoFocus
            autoComplete={autocomplete}
            id={name}
            placeholder={placeholder}
            variant={variant}
            size='sm'
            className={className}
          />
        );
      case 'grid-select':
        return (
          <Select
            {...inputProps}
            variant={variant}
            size='sm'
            placeholder={placeholder}
            options={(options || []).map((option) => ({
              value: option.value,
              label: option.title
            }))}
            className={className}
          />
        );
      default:
        return null;
    }
  }, []);

  return (
    <Box className='w-full items-start gap-1'>
      {label ? (
        <Superscript
          size='xs'
          // @ts-expect-error - TODO: fix Superscript types
          as='label'
          htmlFor={name}
          className='text-bor-foreground/50 text-bor-xs'
        >
          {label}
        </Superscript>
      ) : null}

      <Input {...inputProps} disabled={disabled} onChange={handleOnChange} />

      <span data-testid='field-validation' className='text-coral-500 min-h-5 text-sm'>
        {hasError ? error || submitError : null}
      </span>
    </Box>
  );
};
